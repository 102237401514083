import { varClient } from "../common/defaults";
import { varAuth } from "./defaults";
import { CURRENT_USER, GET_TOKEN, LOG_OUT_SERVER } from "./gql";

export const authMutation = {
  asyncAuth: async ({ token, ...rest }: any) => {
    //console.log("🚀 ~ file: resolvers.ts ~ line 8 ~ asyncAuth: ~ token", token);
    const client = rest.client ?? varClient();

    const auth = varAuth();
    varAuth({
      ...auth,
      token,
    });

    const {
      data: { me },
    } = await client.query({
      query: CURRENT_USER,
      fetchPolicy: "network-only",
    });
    //console.log("🚀 ~ file: resolvers.ts ~ line 19 ~ asyncAuth: ~ me", client);

    if (me) {
      varAuth({
        ...auth,
        isLogin: me ? true : false,
        token: me ? token : null,
        user: me,
      });
    } else {
    }
    await client.resetStore();

    return "done";
  },
  checkAuth: async ({ ...rest }) => {
    const client = rest.client ?? varClient();
    //console.log("🚀 ~ file: resolvers.ts:39 ~ checkAuth: ~ client", client);

    const auth = varAuth();

    const {
      data: { getToken },
    } = await client.mutate({
      mutation: GET_TOKEN,
    });

    if (getToken) {
      varAuth({
        ...auth,
        isLogin: getToken?.user ? true : false,
        token: getToken?.token || null,
        user: getToken?.user,
      });
    } else {
      varAuth(undefined, true);
    }
  },
  logout: async () => {
    const client = varClient();

    varAuth({ authLoading: true }, true);

    client?.mutate &&
      (await client?.mutate({
        mutation: LOG_OUT_SERVER,
      }));
    varAuth({}, true);

    await client.resetStore();
  },
};
