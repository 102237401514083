import styled from "styled-components";
import {Box, Button, Typography} from "@mui/material";
import {ArrowBack} from "@mui/icons-material";
import React, {useContext, useEffect, useRef, useState} from "react";
import {toast} from "react-toastify";
import axios from "axios";
import {useLocation, useNavigate} from "react-router-dom";
import axiosClient, {baseURL} from "../../utils/axios-client";
import {useOtp} from "../../graphql/graphql/auth/hooks";
import {MenuContext} from "../../contexts/menuContext";
import CheckboxComponent from "../checkbox/checkbox";

const BACKSPACE_KEY = 8;
const DELETE_KEY = 46;
const LEFT_KEY = 37;
const RIGHT_KEY = 39;

export default ({
  email,
  title = "Email Verification",
  btnText,
  btnIcon = null,
  handleBack,
  showConsent = false,
  resendOtp,
}) => {
  const inputOne = useRef(null);
  const inputTwo = useRef(null);
  const inputThree = useRef(null);
  const inputFour = useRef(null);
  const navigate = useNavigate();
  const {isSmall} = useContext(MenuContext);
  const [code, setCode] = useState({code1: "", code2: "", code3: "", code4: ""});
  const [consent, setConsent] = useState(true);
  const {verfyOtp, loading} = useOtp();

  const verifyotp = async () => {
    if (!code.code1 || !code.code2 || !code.code3 || !code.code4) {
      toast.error("Please enter a valid OTP");
      return;
    }
    await verfyOtp({email, code: `${code.code1}${code.code2}${code.code3}${code.code4}`});
  };

  const validateCharacter = (e) => {
    if (/[0-9]/.test(e.key)) {
      console.log(e.key);
      setCode((prev) => ({...prev, [e.target.name]: e.key}));
    } else if ([BACKSPACE_KEY, DELETE_KEY].includes(e.keyCode)) {
      setCode((prev) => ({...prev, [e.target.name]: ""}));
    } else {
      e.preventDefault();
    }
  };

  const autoTab = (e, tabindex) => {
    switch (tabindex) {
      case 1:
        if (
          ![BACKSPACE_KEY, DELETE_KEY, LEFT_KEY].includes(e.keyCode) &&
          (e.keyCode === RIGHT_KEY || e.target.value.length === 1)
        ) {
          inputTwo.current.focus();
        }
        break;
      case 2:
        if ([BACKSPACE_KEY, DELETE_KEY, LEFT_KEY].includes(e.keyCode)) {
          inputOne.current.focus();
        } else if (e.keyCode === RIGHT_KEY || e.target.value.length === 1) {
          inputThree.current.focus();
        }
        break;
      case 3:
        if ([BACKSPACE_KEY, DELETE_KEY, LEFT_KEY].includes(e.keyCode)) {
          inputTwo.current.focus();
        } else if (e.keyCode === RIGHT_KEY || e.target.value.length === 1) {
          inputFour.current.focus();
        }
        break;
      case 4:
        if ([BACKSPACE_KEY, DELETE_KEY, LEFT_KEY].includes(e.keyCode)) {
          inputThree.current.focus();
        }
        break;
      default:
        break;
    }
  };

  return (
    <VerifyFormWrapper>
      <Typography
        component={"span"}
        onClick={handleBack}
        sx={{
          color: "#eda044",
          cursor: "pointer",
          display: "flex",
          gap: "12px",
        }}
      >
        <ArrowBack />
        Go back
      </Typography>
      <Typography
        variant="h1"
        color="rgba(255, 255, 255, 0.87)"
        fontFamily="Roboto"
        fontSize={{sm: "36px", xs: "29px"}}
        lineHeight={{sm: "50px", xs: "38px"}}
        fontWeight={500}
        letterSpacing="-0.02em"
        textAlign="center"
        mt="30px"
        mb="5px"
      >
        {title}
      </Typography>
      <Typography color="rgba(255, 255, 255, 0.87)" mb="20px" textAlign="center" fontSize="14px" lineHeight="20px">
        We’ve sent a 4-digit code to {email}. Enter the code below to verify your email address.
      </Typography>

      {/* Code boxes */}
      <InputWrapper>
        {[inputOne, inputTwo, inputThree, inputFour].map((r, i) => (
          <StyledInput
            name={`code${i + 1}`}
            type="number"
            ref={r}
            value={code[`code${i + 1}`]}
            onKeyUp={(e) => autoTab(e, i + 1)}
            onKeyDown={validateCharacter}
            tabIndex={i + 1}
            required
            placeholder="0"
          />
        ))}
      </InputWrapper>
      <Typography
        mt="15px"
        fontSize="14px"
        fontFamily="Inter"
        color="rgba(255, 255, 255, 0.6);"
        textAlign="center"
        lineHeight="20px"
      >
        Didn’t receive a code?{" "}
        <span style={{textDecoration: "underline", cursor: "pointer", color: "#eda044"}} onClick={resendOtp}>
          Click here to resend.
        </span>
      </Typography>

      {showConsent && (
        <CheckboxComponent
          checked={consent}
          onChange={() => setConsent(!consent)}
          label="Send me email receipts, payment reminders, and invite sign ups"
          styles={{
            alignItems: "center",
            justifyContent: "center",
            mt: "30px",
          }}
          labelStyles={{
            color: "rgba(255, 255, 255, 0.6)",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "20px",
          }}
        />
      )}

      <Button style={{marginTop: "30px"}} type="button" startIcon={btnIcon} onClick={verifyotp} loading={loading}>
        {btnText}
      </Button>
    </VerifyFormWrapper>
  );
};

const VerifyFormWrapper = styled.div`
  width: 100%;
  text-align: left;
  @media (max-width: 899px) {
    margin-top: 50px;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  @media (max-width: 400px) {
    gap: none;
    justify-content: space-between;
  }
`;

const StyledInput = styled.input`
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #0f0f0f;
  border: 2px solid rgba(255, 255, 255, 0.12);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  width: 80px;
  height: 80px;
  font-weight: 500;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.02em;
  color: rgba(255, 255, 255, 0.87);
  caret-color: transparent;

  @media (max-width: 400px) {
    margin-top: 10px;
  }

  &:focus {
    outline: 2px solid #eda044;
  }
`;
