import {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {baseURL} from "../../../utils/axios-client";
import {useVarAuth} from "../auth/defaults";

export const useRefetchOnFocus = (refetch) => {
  useEffect(() => {
    const onVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        refetch();
      }
    };
    document.addEventListener("visibilitychange", onVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", onVisibilityChange);
    };
  }, []);
};
// const loginWithGoogleId = "btnGoogle";
export const useGoogleAuth = (
  props: {type: "signin" | "signup"; referrer?: string; data?: any} = {type: "signin", referrer: undefined, data: {}}
) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const {user, asyncAuth} = useVarAuth();
  const divRef = useRef<HTMLDivElement>();

  console.log("🚀 ~ file: hooks.tsx:23 ~ useGoogleAuth ~ user:", user);

  const handleGoogle = async (response) => {
   // console.log("🚀 ~ file: hooks.tsx:23 ~ handleGoogle ~ response:", response);
    setLoading(true);
    fetch(baseURL + "/rest/google/callback", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        credential: response.credential,
        type: props.type,
        referrer: props.referrer,
        username: props.data?.username,
        name: props.data?.name,
      }),
    })
      .then((res) => {
        setLoading(false);

        return res.json();
      })
      .then((data) => {
        if (data?.user) {
          asyncAuth(data?.user).then(() => {
            //console.log("🚀 ~ file: index.jsx:68 ~ verifyOtp ~ user", data?.user);
            localStorage.removeItem("referrer");

            if (data?.user.planEnds < new Date() || data?.user.planStatus !== "subscribed") {
              navigate("/mustSubscribe" + location.search);
            } else {
              // redirect to dashboard
              navigate("/dashboard");
            }
          });
          // localStorage.setItem("user", JSON.stringify(data?.user));
          // window.location.reload();
        } else {
          throw new Error(data?.message || data);
        }
      })
      .catch((error) => {
        setError(error?.message);
        toast.error(error?.message);
      });
  };

  useEffect(() => {
    /* global google */
    //@ts-ignore
    //@ts-ignore
    if (window.google && divRef.current) {
      //@ts-ignore
      google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleGoogle,
      });
      const renderButton = (width) => {
        //@ts-ignore
        google.accounts.id.renderButton(divRef.current, {
          type: "standard",
          theme: "outline",
          logo_alignment: "center",
          size: "large",
          text: props.type == "signin" ? "signin_with" : "signup_with",
          shape: "rectangular",
          // width: props.type == "signin" ? 375 : 410,
          width,
        });
      };
      renderButton(divRef.current?.clientWidth);
      //@ts-ignore
      google.accounts.id.prompt();

      window.addEventListener("resize", () => {
        if (divRef.current && window.innerWidth < 450) {
          renderButton(divRef.current?.clientWidth);
        }
      });
    }
    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);

  return {loginWithGoogleId: divRef, loading, error};
};
