import {createTheme, useMediaQuery} from "@mui/material";
import React, {createContext, useState} from "react";

export const MenuContext = createContext({});

const MenuContextProvider = ({children}) => {
  const [open, setOpen] = useState(false);
  const theme = createTheme({
    palette: {
      mode: "dark",
    },
  });
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const value = {open, setOpen, isSmall, theme};

  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
};

export default MenuContextProvider;
