import React from "react";
import {Link} from "react-router-dom";
import {Box, Button, Typography} from "@mui/material";

import chart from "../../assets/images/chart.svg";
import copy from "../../assets/images/copyBlack.svg";
import complete from "../../assets/images/complete.svg";
import discordWhite from "../../assets/images/discordWhite.svg";
import openLink from "../../assets/images/openLink.svg";
import openLinkBlack from "../../assets/images/openLinkBlack.svg";
import send from "../../assets/images/send.svg";
import {useGetReferralLink} from "../../graphql/graphql/ref/hooks";
import {CustomTooltip} from "../../components/CustomTooltip"
import { useState } from "react";

const SignUpComplete = () => {
  const {handleCopyLink} = useGetReferralLink();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleClickAndShowTooltip = async () => {
    await handleCopyLink();
    setTooltipOpen(true);
    setTimeout(() => setTooltipOpen(false), 2000); // hides tooltip after 2 seconds
  };

  return (
    <Box className="payCompleteMain">
      <img className="completeImage" src={complete} alt="complete" />
      <Typography variant="h5" mt={3} mb={2}>
        Sign up complete!
      </Typography>
      <Typography color="rgba(255, 255, 255, 0.87)" fontSize="14px" fontWeight={400} mb={4}>
        Your account has successfully been created. Thank you for your support.
      </Typography>
      <a href="https://discord.gg/buythedip" target="_blank" rel="noopener noreferrer">
        <Button fullWidth className="btnDiscord">
            <img src={discordWhite} alt="discord" />
            Join the Discord Community
            <img src={openLink} alt="openLink" />
        </Button>
      </a>
      <CustomTooltip title="Copied!" open={tooltipOpen}>
      <Link>
        <Button fullWidth className="btnShare" onClick={handleClickAndShowTooltip}>
          <img src={send} alt="send" />
          Share your link with friends
          <img src={copy} alt="copy" />
        </Button>
      </Link>
    </CustomTooltip>
      <Link to="/dashboard">
        <Button fullWidth className="btnDashboard">
          <img src={chart} alt="chart" />
          Visit your BTD dashboard
          <img src={openLinkBlack} alt="openLink" />
        </Button>
      </Link>
      <Typography variant="h6" mt={2}>
        Need help getting started? <Link to="">Check your email for start up instructions!</Link>
      </Typography>
    </Box>
  );
};

export default SignUpComplete;
