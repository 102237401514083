import axios from "axios";
// const baseURL = "http://localhost:3001";
export const baseURL = process.env.REACT_APP_BACKEND_BASE_URL;

const axiosClient = axios.create({
  baseURL: baseURL + "/api/",
  withCredentials: true,
  // headers: {
  //   Authorization: localStorage.getItem("token")
  //     ? `Bearer ${localStorage.getItem("token")}`
  //     : "",
  // },
});
axiosClient.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token");
    //console.log("🚀 ~ file: axios-client.js:17 ~ token", token);
    config.headers = {
      ...config.headers,
      Authorization: token ? `Bearer ${token}` : "",
    };
    return config;
  },
  function (error) {
    //console.log("🚀 ~ file: axios-client.js:18 ~ error", error);
    // Do something with request error
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  (response) => ({...response.data, data: response.data, ...response}),
  function (error) {
    // if (error.response.status === 401 || error.response.status === 403) {
    //   localStorage.removeItem("user");
    //   localStorage.removeItem("token");
    //   window.location.reload();
    // }
    return Promise.reject(error);
  }
);

export default axiosClient;
