import React, {lazy, Suspense} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import LoadingWall from "./components/loadingWall";
import NotifyContextProvider from "./contexts/notifyContext";
import MenuContextProvider from "./contexts/menuContext";
import ProtectedRoutes from "./route-middleware/protectedRoutes";
import UnProtectedRoute from "./route-middleware/unProtectedRoutes";
import Google from "./pages/google";
import Payment from "./pages/payment";
import MustSubscribe from "./pages/auth/mustSubscribe";
import Signup from "./pages/auth/signup";
import {useApollo} from "./graphql";
import {ApolloProvider} from "@apollo/client";
import {ConfirmProvider} from "material-ui-confirm";
const Layout = lazy(() => import("./layouts/layout"));
const App = () => {
  const client = useApollo();
  return (
    <div className="App">
      <ConfirmProvider>
        <ApolloProvider client={client}>
          <BrowserRouter>
            <NotifyContextProvider>
              <MenuContextProvider>
                <Routes>
                  <Route element={<UnProtectedRoute />}>
                    <Route
                      path="/"
                      element={
                        <Suspense fallback={<LoadingWall />}>
                          <Layout />
                        </Suspense>
                      }
                    />
                    <Route
                      path="/mustSubscribe"
                      element={
                        <Suspense fallback={<LoadingWall />}>
                          <MustSubscribe />
                        </Suspense>
                      }
                    />
                  </Route>
                  <Route
                    path="/signup"
                    element={
                      <Suspense fallback={<LoadingWall />}>
                        <Layout />
                      </Suspense>
                    }
                  />
                </Routes>

                <Routes>
                  <Route element={<ProtectedRoutes />}>
                    <Route
                      path="/dashboard"
                      element={
                        <Suspense fallback={<LoadingWall />}>
                          <Layout />
                        </Suspense>
                      }
                    />
                  </Route>
                  <Route element={<ProtectedRoutes />}>
                    <Route
                      path="/market"
                      element={
                        <Suspense fallback={<LoadingWall />}>
                          <Layout />
                        </Suspense>
                      }
                    />
                  </Route>
                </Routes>
                <Routes>
                  <Route element={<ProtectedRoutes />}>
                    <Route
                      path="/settings"
                      element={
                        <Suspense fallback={<LoadingWall />}>
                          <Layout />
                        </Suspense>
                      }
                    />
                  </Route>
                </Routes>
                <Routes>
                  <Route element={<ProtectedRoutes />}>
                    <Route
                      path="/settings/myaccount"
                      element={
                        <Suspense fallback={<LoadingWall />}>
                          <Layout />
                        </Suspense>
                      }
                    />
                  </Route>
                </Routes>
                <Routes>
                  <Route element={<ProtectedRoutes />}>
                    <Route
                      path="/settings/profile"
                      element={
                        <Suspense fallback={<LoadingWall />}>
                          <Layout />
                        </Suspense>
                      }
                    />
                  </Route>
                </Routes>
                <Routes>
                  <Route element={<ProtectedRoutes />}>
                    <Route
                      path="/settings/privacy-safety"
                      element={
                        <Suspense fallback={<LoadingWall />}>
                          <Layout />
                        </Suspense>
                      }
                    />
                  </Route>
                </Routes>
                <Routes>
                  <Route element={<ProtectedRoutes />}>
                    <Route
                      path="/settings/payouts"
                      element={
                        <Suspense fallback={<LoadingWall />}>
                          <Layout />
                        </Suspense>
                      }
                    />
                  </Route>
                </Routes>
                <Routes>
                  <Route element={<ProtectedRoutes />}>
                    <Route
                      path="/settings/referral-links"
                      element={
                        <Suspense fallback={<LoadingWall />}>
                          <Layout />
                        </Suspense>
                      }
                    />
                  </Route>
                </Routes>
                <Routes>
                  <Route element={<ProtectedRoutes />}>
                    <Route
                      path="/settings/promo-material"
                      element={
                        <Suspense fallback={<LoadingWall />}>
                          <Layout />
                        </Suspense>
                      }
                    />
                  </Route>
                </Routes>
                <Routes>
                  <Route element={<ProtectedRoutes />}>
                    <Route
                      path="/settings/notifications"
                      element={
                        <Suspense fallback={<LoadingWall />}>
                          <Layout />
                        </Suspense>
                      }
                    />
                  </Route>
                </Routes>
                <Routes>
                  <Route element={<ProtectedRoutes />}>
                    <Route
                      path="/settings/subscriptions"
                      element={
                        <Suspense fallback={<LoadingWall />}>
                          <Layout />
                        </Suspense>
                      }
                    />
                  </Route>
                </Routes>
                <Routes>
                  <Route element={<ProtectedRoutes />}>
                    <Route
                      path="/settings/billing"
                      element={
                        <Suspense fallback={<LoadingWall />}>
                          <Layout />
                        </Suspense>
                      }
                    />
                  </Route>
                </Routes>
                <Routes>
                  <Route element={<ProtectedRoutes />}>
                    <Route
                      path="/settings/premium-plans"
                      element={
                        <Suspense fallback={<LoadingWall />}>
                          <Layout />
                        </Suspense>
                      }
                    />
                  </Route>
                </Routes>
                <Routes>
                  <Route element={<ProtectedRoutes />}>
                    <Route
                      path="/settings/send-gift"
                      element={
                        <Suspense fallback={<LoadingWall />}>
                          <Layout />
                        </Suspense>
                      }
                    />
                  </Route>
                </Routes>
                <Routes>
                  <Route
                    path="/api/auth/google/callback"
                    element={
                      <Suspense fallback={<LoadingWall />}>
                        <Google />
                      </Suspense>
                    }
                  />
                </Routes>
              </MenuContextProvider>
            </NotifyContextProvider>
          </BrowserRouter>
        </ApolloProvider>
      </ConfirmProvider>
    </div>
  );
};

export default App;
