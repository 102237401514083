import {Typography} from "@mui/material";

const AlertCustom = ({message, desc = "", toastProps = null}) => {
  return (
    <>
      <Typography variant="body2" fontWeight={600} maxWidth="550px" minWidth="100px" lineHeight="20px">
        {message}
      </Typography>
      <Typography
        variant="body2"
        fontWeight={400}
        maxWidth="550px"
        sx={{opacity: toastProps?.type === "error" ? ".87" : "1"}}
        lineHeight="20px"
        mt="4px"
      >
        {desc}
      </Typography>
    </>
  );
};

export default AlertCustom;
