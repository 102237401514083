import React, {useContext, useEffect, useMemo, useState} from "react";
import {Box, Button, Grid, InputAdornment, Typography} from "@mui/material";
import {GppGoodOutlined, HelpOutline, Login, MailOutline} from "@mui/icons-material";
import {toast} from "react-toastify";
import {useLocation, useNavigate} from "react-router-dom";
import axios from "axios";
import axiosClient, {baseURL} from "../../utils/axios-client";
import {useOtp} from "../../graphql/graphql/auth/hooks";
import {useGoogleAuth} from "../../graphql/graphql/common/hooks";
import google from "../../assets/images/google.svg";
import LayerLogo from "../../assets/images/LayerLogo.svg";
import PhoneOnCouch from "../../assets/images/Phone-on-Couch.png";
import SafePicture from "../../assets/images/Safe-picture.png";
import BalloonPicture from "../../assets/images/Balloons.png";
import EmailVerify from "../../components/auth/emailVerify";
import Textfield from "../../components/auth/form/textfield";
import LeftSidePanel from "../../components/auth/leftSidePanel";
import RightSidePanel from "../../components/auth/rightSidePanel";
import Header from "../../components/auth/form/header";
import Footer from "../../components/auth/form/footer";
import {CustomTooltip} from "../../components/CustomTooltip";
import {MenuContext} from "../../contexts/menuContext";
import {TextDivider} from "../../components/textDivider";
import SignUpComplete from "../payment/signUpComplete";
import {useVarAuth} from "../../graphql/graphql/auth/defaults";
import "./styles.css";
import {useQuery} from "@apollo/client";
import {CURRENT_USER} from "../../graphql/graphql/auth/gql";
import LoadingWall from "../../components/loadingWall";

const Signup = () => {
  const {data: dataMe, loading: userLoading} = useQuery(CURRENT_USER, {});
  const user = dataMe?.me||{}
  const isLogin = !(user.planEnds < new Date() || user.planStatus !== "subscribed");
  console.log("🚀 ~ file: signup.jsx:35 ~ Signup ~ isLogin:", isLogin)
  const [hasSentEmail, setHasSentEmail] = useState(false);
  const [data, setData] = useState({
    name: "",
    userName: new URLSearchParams(window.location.search).get("username") | "",
    email: "",
  });
  const navigate = useNavigate();
  const location = useLocation();
useEffect(() => {
  if (userLoading) return
  if (!isLogin&&user.id) {
    navigate("/mustSubscribe")
  }

} , [user])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get("plan")) {
      localStorage.setItem("plan", params.get("plan"));
    }

    setData((prev) => ({...prev, userName: params.get("username") || ""}));
  }, []);

  const referrer = useMemo(() => {
    const ref = new URLSearchParams(window.location.search).get("referrer");
    if (ref) {
      localStorage.setItem("referrer", ref);
      return ref;
    } else {
      return localStorage.getItem("referrer");
    }
  }, [location]);

  const {isSmall} = useContext(MenuContext);
  const {sendOtp: sendOTP, loading} = useOtp();
  // const {loginWithGoogleId} = useGoogleAuth({type: "signup", referrer: localStorage.getItem("referrer"), data});
  const sendOtp = async (noType) => {
    await sendOTP({
      email: data.email,
      type: noType ? undefined : "signup",
      referrer: localStorage.getItem("referrer"),
      name: data.name,
      userName: data.userName,
    }).then((res) => {
      localStorage.removeItem("referrer");
    });

    setHasSentEmail(true);
  };

  const handleInputChange = ({target}) => setData((prev) => ({...prev, [target.name]: target.value}));
  if (userLoading)
    return (
      <div>
        <LoadingWall />{" "}
      </div>
    );

  return (
    <Box className="paymentMain">
      <Grid container>
        {!isSmall && (
          <LeftSidePanel
            image={isLogin ? BalloonPicture : hasSentEmail ? SafePicture : PhoneOnCouch}
            title={
              isLogin
                ? "You are officially part of the BTD community."
                : hasSentEmail
                ? `Welcome, ${data.name}!`
                : `Hi @${data.userName || ""},`
            }
            description={
              isLogin
                ? "Gift your friends 20% off their first month, send them your referral link."
                : hasSentEmail
                ? "After 3 referrals, your membership is free. Then you start getting paid monthly!"
                : "Profits are nice. Becoming a legenday trader? Now that's NICE. Welcome to BTD, where profits are made & great traders are bred."
            }
            highlight={isLogin ? "20% off" : hasSentEmail ? "paid monthly" : "NICE"}
          />
        )}
        <RightSidePanel showInfo={!(isSmall || hasSentEmail || isLogin)} maxWidth={500}>
          {isLogin ? (
            <SignUpComplete />
          ) : (
            <div className="max-w-[400px] mx-auto">
              {isSmall && <img src={LayerLogo} alt="logo" style={{width: "97px"}} />}
              {!hasSentEmail && (
                <Header text="Sign up to buythedip.com" btnText="Log in instead" onClick={() => navigate("/")} />
              )}
              <Box className="loginMain" width="100%">
                {hasSentEmail ? (
                  <EmailVerify
                    email={data.email}
                    title="Verify your email address"
                    btnText="Verify"
                    btnIcon={<GppGoodOutlined />}
                    handleBack={() => setHasSentEmail(false)}
                    showConsent
                    resendOtp={() => sendOtp()}
                  />
                ) : (
                  <>
                    <Box display="flex" flexDirection={isSmall ? "column-reverse" : "column"}>
                      <Box display="flex" flexDirection="column" gap="20px">
                        <Box display="flex" flexDirection={isSmall ? "column" : "row"} gap="20px">
                          <Textfield
                            label="Name"
                            name="name"
                            value={data.name}
                            onChange={handleInputChange}
                            sx={{flex: 1}}
                          />
                          <Textfield
                            label="Username"
                            name="userName"
                            value={data.userName}
                            onChange={handleInputChange}
                            InputProps={{startAdornment: "@"}}
                            sx={{flex: 1}}
                          />
                        </Box>
                        <Textfield label="Email" name="email" value={data.email} onChange={handleInputChange} />
                        <Button
                          onClick={() => sendOtp()}
                          loading={loading}
                          startIcon={<MailOutline />}
                          sx={{my: "5px", mb: !isSmall && "0px !important"}}
                        >
                          Sign up with email
                        </Button>
                        {referrer && (
                          <Textfield
                            label="Referred by:"
                            value={`@${referrer}`}
                            disabled
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <CustomTooltip title="Help!">
                                    <HelpOutline sx={{fill: "#FFFFFF61"}} />
                                  </CustomTooltip>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      </Box>
                      {/* <TextDivider text="OR" my="20px" />
                      <div ref={loginWithGoogleId} className="max-h-[44px]" /> */}
                    </Box>
                    {!hasSentEmail && (
                      <Typography textAlign="left" mt="30px" variant="body2">
                        By clicking continue, you agree to our{" "}
                        <a
                          href="https://www.buythedip.com/legal"
                          style={{textDecoration: "underline", color: "#EDA044", fontWeight: 400}}
                        >
                          Terms of Service
                        </a>{" "}
                        and{" "}
                        <a
                          href="https://www.buythedip.com/privacy"
                          style={{textDecoration: "underline", color: "#EDA044", fontWeight: 400}}
                        >
                          Privacy Policy
                        </a>
                        .
                      </Typography>
                    )}
                  </>
                )}
              </Box>
            </div>
          )}
        </RightSidePanel>
      </Grid>
    </Box>
  );
};

export default Signup;
