import { makeVar, useReactiveVar } from "@apollo/client";
import { getCache, setCache } from "../../../utils/cacheService";
import { authMutation } from "./resolvers";
const initState: any = {
  isLogin: false,
  user: null,
  token: "",
  loading: false,
};

const auth = makeVar({ ...(getCache("auth") || initState), loading: true });
export const varAuth = (newVal?: any, reset?: any) => {
  //console.log("🚀 ~ file: defaults.ts:13 ~ varAuth ~ newVal", newVal);
  if (reset) {
    setCache("auth", { ...initState, ...newVal });
    return auth({ ...initState, ...newVal });
  }
  if (typeof newVal !== "undefined") {
    setCache("auth", newVal);
    return auth(newVal);
  }
  return auth();
};

export const useVarAuth = () => {
  const newData = useReactiveVar(auth);

  return { ...newData, ...authMutation };
};

export const setAuthDefaults = () => {
  const authCache = getCache("auth");

  auth({ ...(authCache || initState), loading: false });
};
