import React from "react";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {Box, Button, Typography} from "@mui/material";
import CheckoutForm from "./checkoutForm";
import "./payment.css";

const stripe = loadStripe(process.env.REACT_APP_STRIPE_SECRET_KEY);

const PayManually = ({selectedPlan, setFormStep, setCreatedUser, setPaymentFlow, setShowAlert}) => {
  return (
    <Box className="manuallyMain" mt={6}>
      {/* <Box className='manuallyTitle' my={4}>
                <Typography textAlign={"center"}>Or pay manually</Typography>
            </Box> */}
      {stripe && (
        <Elements stripe={stripe}>
          <CheckoutForm
            setFormStep={setFormStep}
            selectedPlan={selectedPlan}
            setCreatedUser={setCreatedUser}
            setPaymentFlow={setPaymentFlow}
            setShowAlert={setShowAlert}
          />
        </Elements>
      )}
      <Typography textAlign={"center"} variant="h4" mt={3} className="referTitle">
        Refer 3 friends and your membership is free.
      </Typography>
      <Typography textAlign={"center"} variant="h3" mb={4} mt={3}>
        By confirming your subscription, you allow thisApp.com to charge your card for this payment and future payments
        in accordance with our terms. You can always cancel your subscription.
      </Typography>
      <Typography textAlign={"center"} variant="h3">
        Plan auto-renews unless cancelled.
      </Typography>
    </Box>
  );
};

export default PayManually;
