import React, {useContext, useEffect, useRef, useState} from "react";
import {Box, Button, Grid, Typography} from "@mui/material";
import {Login, MailOutline, ShieldOutlined} from "@mui/icons-material";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import axiosClient, {baseURL} from "../../../utils/axios-client";
import {useOtp} from "../../../graphql/graphql/auth/hooks";
import {useGoogleAuth} from "../../../graphql/graphql/common/hooks";
import google from "../../../assets/images/google.svg";
import LayerLogo from "../../../assets/images/LayerLogo.svg";
import DonutImage from "../../../assets/images/Donut.png";
import AirplaneImage from "../../../assets/images/airplane.png";
import EmailVerify from "../../../components/auth/emailVerify";
import Textfield from "../../../components/auth/form/textfield";
import {TextDivider} from "../../../components/textDivider";
import LeftSidePanel from "../../../components/auth/leftSidePanel";
import RightSidePanel from "../../../components/auth/rightSidePanel";
import Header from "../../../components/auth/form/header";
import Footer from "../../../components/auth/form/footer";
import {MenuContext} from "../../../contexts/menuContext";
import "./login.css";

export const emailRegex =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const payFlow = [
  {title: "Sign Up", detail: "In less than 5 seconds"},
  {title: "Payment", detail: "Enter payment details"},
];

const LogIn = () => {
  const [hasSentEmail, setHasSentEmail] = useState(false);
  const [stripePrices, setStripePrices] = useState(null);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const {isSmall} = useContext(MenuContext);
  const {sendOtp: sendOTP, loading} = useOtp();
  const {loginWithGoogleId} = useGoogleAuth({type: "signin"});
  const sendOtp = async (noType) => {
    await sendOTP({email, type: noType ? undefined : "signin"});
    setHasSentEmail(true);
  };

  return (
    <Box className="paymentMain">
      <Grid container>
        {!isSmall && (
          <LeftSidePanel
            image={hasSentEmail ? AirplaneImage : DonutImage}
            title="Welcome back!"
            description={
              hasSentEmail
                ? "Don't let this one fly over your head. You could be getting paid thousands monthly. Refer your friends now."
                : "Do-nut let this opportunity pass by. Refer more than 3 friends and get paid monthly."
            }
            highlight={hasSentEmail ? "fly" : "Do-nut"}
          />
        )}
        <RightSidePanel>
          {stripePrices ? (
            <StripePrices prices={stripePrices} />
          ) : (
            <>
              {isSmall && <img src={LayerLogo} alt="logo" />}
              {!hasSentEmail && (
                <Header text="Log in to buythedip.com" btnText="Sign up instead" onClick={() => navigate("/signup")} />
              )}
              <Box className="loginMain">
                {hasSentEmail ? (
                  <EmailVerify
                    email={email}
                    btnText="Log in"
                    btnIcon={<Login />}
                    handleBack={() => setHasSentEmail(false)}
                  />
                ) : (
                  <>
                    <Box display="flex" flexDirection={isSmall ? "column-reverse" : "column"}>
                      <Box>
                        <Textfield
                          label="Username or Email"
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <Button
                          type="submit"
                          onClick={() => sendOtp()}
                          loading={loading}
                          startIcon={<MailOutline />}
                          sx={{mt: "20px", mb: !isSmall && "0px !important"}}
                        >
                          Sign in with email
                        </Button>
                      </Box>
                      <TextDivider text="OR" my="20px" />
                      <div id={loginWithGoogleId} />
                    </Box>
                    <Footer
                      text="Don't have an account?"
                      btnText="Sign up instead"
                      onClick={() => navigate("/signup")}
                    />
                  </>
                )}
              </Box>
            </>
          )}
        </RightSidePanel>
      </Grid>
    </Box>
  );
};

const StripePrices = ({prices}) => {
  // const [selectedPrice, setSelectedPrice] = useState(null);
  const sortedPrices = prices.sort((a, b) => a.unit_amount - b.unit_amount);
  return (
    <Box className="loginMain">
      <Box textAlign={"left"}>
        <div>
          <h1 className="text-white mb-2 text-center text-4xl">Choose your plan</h1>

          <h2 className="text-white/87 mb-6 text-center">Choose a plan that fits your needs.</h2>

          <div className="flex flex-row items-center justify-center space-x-4">
            {sortedPrices.map((price) => (
              <div
                key={price.id}
                className="bg-[#0F0F0F] border-[2px] border-white/10 w-64  text-5xl text-white/40 rounded-md border-solid text-center py-3 px-3"
              >
                <h2 className="text-white mb-2 text-center text-4xl">{price.nickname}</h2>
                {price.recurring.interval === "month" && (
                  <h3 className="text-white/87 mb-3 text-center">{price.recurring.interval_count} Month</h3>
                )}
                {price.recurring.interval === "year" && (
                  <h3 className="text-white/87 mb-3 text-center">{price.recurring.interval_count} Year</h3>
                )}
                <h3 className="text-white/87 mb-3 text-center">
                  {price.unit_amount / 100} {price.currency}
                </h3>

                <Button
                  style={{marginTop: "30px"}}
                  type="button"
                  startIcon={<ShieldOutlined />}
                  onClick={() => {
                    //console.log(price);
                  }}
                >
                  Subscribe
                </Button>
              </div>
            ))}
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default LogIn;
