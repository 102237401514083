import {Box, FormControl, Grid, OutlinedInput, Typography} from "@mui/material";
import parse from "html-react-parser";
import LayerLogo from "../../assets/images/LayerLogo.svg";
import "../../pages/auth/styles.css";

export default ({image, title, description, highlight = null}) => (
  <Grid item md={5}>
    <Box className="sigupLeftPanel signupCompleteMain">
      <Box px="25px">
        <img src={LayerLogo} alt="logo" />
        <Typography mt="28px" mb={1}>
          {title}
        </Typography>
        <Typography variant="h4" fontSize="32px" lineHeight="38px">
          {highlight ? parse(description.replace(highlight, `<b>${highlight}</b>`)) : description}
        </Typography>
      </Box>
      <img alt="Illustration" src={image} style={{height: "55%"}} />
    </Box>
  </Grid>
);
