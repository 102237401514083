import {styled} from "@mui/material/styles";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";

export const CustomTooltip = styled(({className, ...props}) => (
  <Tooltip arrow placement="top" {...props} classes={{popper: className}} />
))(({theme}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1b1b1b",
    color: "rgba(255, 255, 255, 1)",
    borderRadius: "8px",
    fontWeight: 600,
    fontSize: "12px",
    fontFamily: "Inter",
    ".MuiTooltip-arrow": {
      color: "#494949 !important",
    },
  },
}));
