import {useMutation} from "@apollo/client";
import {useLocation, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {SEND_OTP, VERIFY_OTP} from "./gql";
import {authMutation} from "./resolvers";
export const emailRegex =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const useOtp = () => {
  const [send, {loading, error}] = useMutation(SEND_OTP, {
    fetchPolicy: "no-cache",
  });
  const [verfy, {loading: vergyLoading, error: verfyError}] = useMutation(VERIFY_OTP, {
    fetchPolicy: "no-cache",
  });
  const location = useLocation();

  const navigate = useNavigate();
  const sendOtp = async (input) => {
    if (emailRegex.test(input.email)) {
      await send({variables: {input: input}});
      toast.success("OTP sent to your email");
    } else {
      toast.error("Please enter a valid email");

      throw new Error("Please enter a valid email");
    }
  };
  const verfyOtp = async ({email, code}) => {
    const {data} = await verfy({variables: {input: {email, code}}});
    //console.log("🚀 ~ file: index.jsx:44 ~ verifyOtp ~ data", data);
    toast.success("OTP verified");
    const user = data.verifyOtp.user;
    const token = data.verifyOtp.token;

    authMutation.asyncAuth({token}).then(() => {
      if (user.planEnds < new Date() || user.planStatus !== "subscribed") {
        //console.log("🚀 ~ file: index.jsx:68 ~ verifyOtp ~ user", user);
        navigate("/mustSubscribe" + location.search);
      }
    });
  };

  return {sendOtp, verfyOtp, loading: loading || vergyLoading, error: error || verfyError};
};
