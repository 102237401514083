// import fetch from "isomorphic-unfetch";
import {endpoint, wsEndPoint} from "./config";
import {ApolloClient, HttpLink, InMemoryCache, from, split} from "@apollo/client";
import {setDefaults} from "./defaults";
import {useMemo} from "react";

import {setContext} from "@apollo/client/link/context";
import {onError} from "@apollo/client/link/error";
import {toast} from "react-toastify";

import {varClient} from "./graphql/common/defaults";
import {varAuth} from "./graphql/auth/defaults";
import {GraphQLWsLink} from "@apollo/client/link/subscriptions";
import {createClient} from "graphql-ws";
import {getMainDefinition} from "@apollo/client/utilities";

const errorLink = onError(({graphQLErrors, networkError}) => {
  if (graphQLErrors) {
    graphQLErrors.map(({message: msg}) => {
      // message.error(msg);
      toast.error(msg, {
        position: "top-left",
      });
    });
  }
  if (networkError) {
    // showMessage({
    //   message: networkError.message,
    //   type: 'danger',
    // });
  }
});

export function useApollo() {
  const store = useMemo(() => createApolloClient(), []);
  // useMemo(() => {
  //   if (!ssrMode) {
  //     authMutation.checkAuth({ client: store });
  //     // authMutation.asyncAuth({ token: varAuth().token, client: store });
  //   }
  // }, [store]);

  varClient(store);

  return store;
}

export default function createApolloClient(initialState?: any) {
  const contextLinkForCreateApolloClient = setContext(() => {
    const headers: any = {
      authorization: varAuth()?.token ? `bearer ${varAuth().token}` : "",
    };
    if (initialState?.builderDomain) {
      headers.builderDomain = initialState?.builderDomain;
    }
    return {
      headers,
    };
    // const shop =
    //   process.env.NODE_ENV !== "development"
    //     ? !ssrMode && window.location.hostname
    //     : DEFAULT_BUILDER;
  });
  const cache = new InMemoryCache({
    typePolicies: {
      // Type policy map
      Query: {
        fields: {},
      },
    },
  }).restore(initialState || {});

  const uri = endpoint;

  const httpLink = new HttpLink({
    credentials: "include",
    // fetch, // Switches between unfetch & node-fetch for client & server.
    uri,
  });
  const wsLink = new GraphQLWsLink(
    createClient({
      url: wsEndPoint,
      connectionParams: () => {
        const headers: any = {
          authorization: varAuth()?.token ? `bearer ${varAuth().token}` : "",
        };
        if (initialState?.builderDomain) {
          headers.builderDomain = initialState?.builderDomain;
        }
        return {
          headers,
        };
      },
    })
  );

  const splitLink = split(
    ({query}) => {
      const definition = getMainDefinition(query);
      return definition.kind === "OperationDefinition" && definition.operation === "subscription";
    },
    wsLink,
    httpLink
  );
  const client = new ApolloClient({
    link: from([errorLink, contextLinkForCreateApolloClient, splitLink]),
    cache,
    // resolvers,
    // typeDefs,
  });
  varClient(client);
  setDefaults();

  client.onResetStore(async () => setDefaults());

  return client;
}
