import {gql} from "@apollo/client";

export const GET_REFERRAL_STATS = gql`
  query GET_REFERRAL_STATS {
    getReferralStats {
      activeReferralCount
      newReferralCountThisDay
      allCreatedReferrals
      allCreatedReferralsThisMonth
      sumOfCreditsToActiveReferral
      sumOfCreditsToActiveReferralInDollars
      redeemableCredits
    }
  }
`;

export const GET_CREDIT_PER_DAY_IN_THIS_WEEK = gql`
  query GET_CREDIT_PER_DAY_IN_THIS_WEEK {
    getCreditPerDayInThisWeek
  }
`;

export const GET_REF_PER_DAY_IN_THIS_WEEK = gql`
  query GET_REF_PER_DAY_IN_THIS_WEEK {
    getRefPerDayInThisWeek
  }
`;

export const GET_COMMISSIONS = gql`
  query GET_COMMISSIONS {
    getCommissions
  }
`;

export const GET_REFERRAL_HISTORY = gql`
  query GET_REFERRAL_HISTORY {
    getReferralHistory
  }
`;
