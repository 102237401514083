import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axiosClient from "../../utils/axios-client";

export default function Index(props) {
  const { search } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    axiosClient
      .get(`/auth/google/callback${search}`)
      .then((response) => {
        localStorage.setItem("user", JSON.stringify(response.data));
        window.location.href = `${window.location.origin}/dashboard`;
      })
      .catch((error) => {
        //console.log("error", error);
        navigate("/");
      });
  }, []);

  return <div />;
}
