import React, { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Formik } from 'formik';
import { Box, Button, FormControl, OutlinedInput, Tooltip, Typography } from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CheckboxComponent from '../../components/checkbox/checkbox';
import { NotifyContext } from '../../contexts/notifyContext';
import axiosClient from '../../utils/axios-client';

import question from "../../assets/images/question.svg";
import "./payment.css";

const referrerDefination = (
    <Box className='refTooltip'>
        <Typography variant='h2'>What's a referrer?</Typography>
        <Typography>You used someone's referral link and got 20% off your first month because of it. They will get rewarded for referring you. You can get paid monthly as well for referring your friends.</Typography>
    </Box>
);

const PaySignUp = ({ createdUser, setFormStep, showAlert }) => {
    const [searchParams] = useSearchParams();
    const referrer = searchParams.get("referrer");
    const { toast } = useContext(NotifyContext);

    const handleSubmit = async (createdUser) => {
        try {
            await axiosClient.post('/auth/password/usercreate', {
                email: createdUser?.email,
                password: createdUser?.password, referrer: referrer || createdUser?.referrercode || "",
                name: createdUser?.name ?? "",
                consent: createdUser?.consent,
                terms: createdUser?.terms
            });
            const data = await axiosClient.post("/auth/password", { email: createdUser?.email, password: createdUser?.password })
            localStorage.setItem("user", JSON.stringify(data.data));
            setFormStep(3);
        } catch (error) {
            toast({ type: "error", message: "There was some error while create an user" });
        }
    };

    const validate = async (values) => {
        const errors = {};
        if (!values.email) {
            errors.email = 'Required';
        }
        if (!values.password) {
            errors.password = 'Required';
        }
        if (!values.terms) {
            errors.terms = 'Please accept terms and conditions';
        }
        return errors;
    };

    return (
        <Formik
            initialValues={createdUser}
            validate={(values) => validate(values)}
            onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)} >
            {
                ({
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isValid,
                    dirty,
                    setFieldValue
                }) => (
                    <Box className='paySignUpMain' sx={{ pt: showAlert ? "10px" : "130px" }}>
                        <Box className='payField'>
                            {console.log("errors", errors)}
                            <Typography variant="h6" className='fieldLabel'>Email</Typography>
                            <FormControl fullWidth variant="outlined">
                                <OutlinedInput placeholder="Enter your email" inputProps={{ "aria-label": "weight" }} name="email" disabled value={values?.email} />
                            </FormControl>
                            <Box className='checkControl'>
                                <CheckboxComponent defaultChecked={true} label="Remember me" />
                            </Box>
                        </Box>
                        <Box mt={3} className='payField'>
                            <Typography variant="h6" className='fieldLabel'>Password</Typography>
                            <FormControl fullWidth variant="outlined" className={errors.password ? "error-input" : ""}>
                                <OutlinedInput type='password' required placeholder="Password" inputProps={{ "aria-label": "weight" }} name="password" id="password" value={values?.password} onChange={handleChange} onBlur={handleBlur}
                                    endAdornment={errors.password && <ErrorOutlineOutlinedIcon color='error' />} />
                            </FormControl>
                        </Box>
                        <Box mt={3} className='payField referrerField'>
                            <Typography variant="h6" className='fieldLabel'>Referrer ID</Typography>
                            <FormControl fullWidth variant="outlined">
                                <OutlinedInput placeholder="Enter Referrer ID" inputProps={{ "aria-label": "weight" }} name="referrercode" disabled={referrer} value={referrer || createdUser?.referrercode} onChange={handleChange} />
                                <Tooltip title={referrerDefination} arrow placement="top-end">
                                    <img src={question} alt="question" />
                                </Tooltip>
                            </FormControl>
                        </Box>
                        <Button fullWidth className='btnPaySignUp btnGPay' disabled={!(isValid && dirty)} onClick={handleSubmit}>Complete</Button>
                        <Box className='checkControl'>
                            <CheckboxComponent checked={values.consent} onChange={() => setFieldValue('consent',!values.consent)} label="Send me email receipts, payment reminders, and invite sign ups" />
                        </Box>
                        <Box className='checkControl'>
                            <CheckboxComponent checked={values.terms} onChange={() => !values.terms && setFieldValue('terms',!values.terms)} label="I agree to terms of use and acknowledge privacy policy." />
                        </Box>
                        <Typography textAlign={"center"} variant="h4" mt={3} className="referTitle">Refer 3 friends and your membership is free.</Typography>
                    </Box>
                )
            }
        </Formik>
    )
}

export default PaySignUp;
