import {Image, ShieldOutlined} from "@mui/icons-material";
import {Box, Button, CircularProgress, Divider, Grid, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {payFlow} from "../loginTemp";
import {useVarAuth} from "../../../graphql/graphql/auth/defaults";
import {useCreateStripeCheckoutSession, useGetStripePrices} from "../../../graphql/graphql/stripe/hooks";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CheckIcon from "../../../assets/images/check.svg";
import PlusIcon from "../../../assets/images/plus.svg";
import {CURRENT_USER} from "../../../graphql/graphql/auth/gql";
import {useQuery} from "@apollo/client";

const MustSubscribe = () => {
  const navigate = useNavigate();
  const {logout} = useVarAuth();
  const {data,loading:userLoading} = useQuery(CURRENT_USER, {
    pollInterval: 1000,
  });
  const user = data?.me;
  const {prices} = useGetStripePrices();
  const [loading, setLoading] = useState(true);
  const {createCheckoutSession} = useCreateStripeCheckoutSession();

  useEffect(() => {
    (async () => {
      if(userLoading) return;
      //console.log("🚀 ~ file: index.jsx:24 ~ user", user);
      if (!user) return navigate("/");

      if (!(user.planEnds < new Date() || user.planStatus !== "subscribed")) {
        return navigate("/dashboard");
      }

      if (localStorage.getItem("plan")) {
        const sortedPrices = [...prices].sort((a, b) => a.unit_amount - b.unit_amount);
        const priceId = sortedPrices[Number(localStorage.getItem("plan")) - 1].id;
        //console.log("🚀 ~ file: index.jsx:31 ~ priceId:", priceId);
        if (priceId) {
          try {
            localStorage.removeItem("plan");
            await createCheckoutSession({variables: {input: {priceId}}});
          } catch (e) {
            //console.log("🚀 ~ file: index.jsx:172 ~ onClick={ ~ e", e);
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    })();
  }, [user]);

  return (
    <Box className="paymentMain">
      <div className="sigupRightPanel !overflow-auto">
        <div className="paymentWrap">
          <Grid justifyContent="center" container>
            <Box className="tabSect w-full lg:w-1/2">
              <Grid container spacing={2}>
                {payFlow.map((pay, key) => (
                  <Grid item xs={6} key={key}>
                    <Box
                      className={`flowTitle ${(key === 0 || prices) && "activeFlow"}`}
                      // onClick={() => {
                      //   !key && setHasSentEmail(false);
                      // }}
                    >
                      <Box className="bgBorder"></Box>
                      <Typography variant="h5" mt={1}>
                        {pay.title}
                      </Typography>
                      <Typography>{pay.detail}</Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
          {prices && !loading ? (
            <StripePrices prices={prices} />
          ) : (
            // loading
            <div className="flex justify-center items-center">
              <CircularProgress />
            </div>
          )}
          {/*signout*/}
          <div className="w-full flex flex-wrap gap-2 justify-end items-center">
            <span className="text-base font-medium"> Not {user?.email}?</span>
            <Button
              variant="outlined"
              // danger
              color="error"
              className="rounded-md whitespace-nowrap"
              onClick={async () => {
                await logout();
                navigate("/");
              }}
            >
              Sign out
            </Button>
          </div>
        </div>
      </div>
    </Box>
  );
};

const StripePrices = ({prices}) => {
  //console.log("🚀 ~ file: index.jsx:92 ~ prices", prices);
  const sortedPrices = [...prices].sort((a, b) => a.unit_amount - b.unit_amount);
  //console.log("🚀 ~ file: index.tsx:108 ~ StripePrices ~ sortedPrices:", sortedPrices);
  const {createCheckoutSession, loading} = useCreateStripeCheckoutSession();
  const sortedPricesWithFeatures = {
    month1: {
      title: "Essential plan",
      desc: "Next billing cycle in 30 days",
      features: {
        fixed: [
          "Real time trade alerts",
          "Long term portfolio buys",
          "Master technical analysis",
          "Exclusive trading community",
          "buythedip.com Discord bots",
        ],
      },
    },
    month3: {
      title: "Premium plan",
      desc: "Next billing cycle in 90 days",
      features: {
        fixed: ["Everything in Essential"],
        addable: ["Free 1 on 1 lesson on finding trades"],
      },
    },
    year1: {
      title: "Gold plan",
      desc: "Next billing cycle in 12 months",
      features: {
        fixed: ["Everything in Premium"],
        addable: ["3 free 1 on 1 lessons on finding trades", "Early access to new features", "Special community rank"],
      },
    },
  };

  return (
    <Box className="loginMain fullWidthDiv">
      <Box textAlign={"left"}>
        <div>
          <h1 className="text-white mb-2 text-center text-3xl md:text-4xl">Pick your plan</h1>
          <h2 className="!text-white/60 mb-6 !not-italic !text-sm md:!text-base text-center">
            Trade without a blindfold. Cancel anytime (stays active until end of billing cycle).
          </h2>
          <Grid
            container
            justifyContent="center"
            spacing={2}
            style={{width: "100%", display: "flex", alignItems: "stretch"}}
          >
            {sortedPrices.map((price, index) => {
              const costPerMonth =
                price.unit_amount /
                (price.recurring.interval_count *
                  (price.recurring.interval === "year" ? 12 : price.recurring.interval === "month" ? 1 : 3));
              const savings = 100 - (costPerMonth / sortedPrices[0].unit_amount) * 100;

              const currPlan = sortedPricesWithFeatures[price.recurring.interval + price.recurring.interval_count];
              //console.log("🚀 ~ file: index.tsx:164 ~ {sortedPrices.map ~ currPlan:", currPlan);
              return (
                <Grid item xs={12} sm={12} md={6} lg={4} style={{display: "flex"}}>
                  <Card
                    className="pricing-card"
                    sx={{
                      background: "#1b1b1b",
                      color: "#a4a4a4",
                      marginBottom: "20px",
                      borderRadius: "12px",
                      border: "1px solid",
                      borderColor: "#272727",
                      flex: "1",
                      display: "flex",
                      flexDirection: "column",
                    }}
                    key={price.id}
                  >
                    <CardContent
                      className="card-content"
                      sx={{
                        minHeight: `200px`,
                        flex: "1",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="mb-4">
                        <div className="flex justify-between items-center">
                          <h2 className="!text-white !text-base md:!text-lg !not-italic">{currPlan.title}</h2>
                          {index > 0 && (
                            <span className="bg-primary/5 text-primary text-xs md:text-sm rounded-2xl py-1 px-3">
                              Save {savings.toFixed(0)}%
                            </span>
                          )}
                        </div>
                        <div className="flex items-baseline">
                          <span className="text-5xl md:text-6xl font-bold text-primary ">
                            {price.currency.toLocaleUpperCase() === "USD"
                              ? `$${price.unit_amount / 100}`
                              : `${(price.unit_amount / 100).toFixed(2)} ${price.currency.toUpperCase()}`}
                          </span>
                          <span className="interval-details">
                            {price.recurring.interval_count === 1 && price.recurring.interval === "month" && "/ month"}
                            {price.recurring.interval === "year" && "/ year"}
                            {price.recurring.interval_count === 3 &&
                              price.recurring.interval === "month" &&
                              "/ 3 months"}
                          </span>
                        </div>
                        <p className="">{currPlan.desc}</p>
                      </div>
                      <Button
                        className="subscribe-button"
                        variant="contained"
                        // startIcon={<ShieldOutlined />}
                        disabled={loading}
                        sx={{background: "#eda044", padding: "12px"}}
                        onClick={async () => {
                          try {
                            await createCheckoutSession({
                              variables: {
                                input: {
                                  priceId: price.id,
                                },
                              },
                            });
                          } catch (e) {
                            //console.log("🚀 ~ Error in clicking subscribe button", e);
                          }
                        }}
                      >
                        Get started
                      </Button>
                      <Divider className="!my-4 !border-[#ffffff0d]" />
                      <div className="flex flex-col gap-4">
                        {currPlan.features.fixed.map((fx, i) => (
                          <span className="flex gap-3 items-center">
                            <img src={CheckIcon} width={24} height={24} alt="check icon" />
                            {fx}
                          </span>
                        ))}
                      </div>
                      {currPlan.features?.addable?.length && (
                        <div className="flex flex-col gap-4 mt-3">
                          {currPlan.features.addable.map((fx, i) => (
                            <span className="flex gap-3 items-center">
                              <img src={PlusIcon} width={24} height={24} alt="check icon" />
                              {fx}
                            </span>
                          ))}
                        </div>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </Box>
    </Box>
  );
};

export default MustSubscribe;
