import React from "react";
import {Checkbox, FormControlLabel, FormGroup} from "@mui/material";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";

import "./checkbox.css";

const CheckboxComponent = ({label, checked, onChange, disabled, styles = {}, labelStyles = {}}) => {
  return (
    <FormGroup className="checkboxMain" sx={styles}>
      <FormControlLabel
        disabled={disabled}
        onChange={onChange}
        checked={checked}
        control={<Checkbox icon={<SquareRoundedIcon />} checkedIcon={<CheckBoxRoundedIcon />} />}
        label={label}
        sx={{"span.MuiTypography-root": labelStyles}}
      />
    </FormGroup>
  );
};

export default CheckboxComponent;
