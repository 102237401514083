import {useQuery} from "@apollo/client";
import moment from "moment";
import {useEffect, useState} from "react";
import {useVarAuth} from "../auth/defaults";
import {useRefetchOnFocus} from "../common/hooks";
import {
  GET_COMMISSIONS,
  GET_CREDIT_PER_DAY_IN_THIS_WEEK,
  GET_REF_PER_DAY_IN_THIS_WEEK,
  GET_REFERRAL_HISTORY,
  GET_REFERRAL_STATS,
} from "./gql";

interface ReferralData {
  activeReferralCount: number;
  newReferralCountThisDay: number;
  allCreatedReferrals: number;
  allCreatedReferralsThisMonth: number;
  sumOfCreditsToActiveReferral: number;
  sumOfCreditsToActiveReferralInDollars: number;
}

export const useRefData: () => {data: ReferralData; loading; error} = () => {
  const {data, error, loading, refetch} = useQuery(GET_REFERRAL_STATS);
  useRefetchOnFocus(refetch);

  return {
    data: data?.getReferralStats,
    error,
    loading,
  };
};
export const useGetCreditPerDayInThisWeek = () => {
  const {data, error, loading, refetch} = useQuery(GET_CREDIT_PER_DAY_IN_THIS_WEEK);

  useRefetchOnFocus(refetch);

  return {
    data: data?.getCreditPerDayInThisWeek || [],
    error,
    loading,
  };
};
export const useGetRefPerDayInThisWeek = () => {
  const {data, error, loading, refetch} = useQuery(GET_REF_PER_DAY_IN_THIS_WEEK);
  useRefetchOnFocus(refetch);
  const [refsPerDay, setRefsPerDay] = useState({});
  const refs = data?.getRefPerDayInThisWeek || [];
  // console.log("🚀 ~ file: hooks.tsx:47 ~ useGetRefPerDayInThisWeek ~ refs:", refs);

  // refs.forEach((ref) => {
  //   const date = moment(ref?.createdAt).format("YYYY-MM-DD"); // Extract the date from the timestamp
  //   refsPerDay[date] = (refsPerDay[date] || 0) + 1; // Add the credits to the corresponding date
  // });
  // console.log("🚀 ~ file: hooks.tsx:52 ~ refs.forEach ~ refsPerDay:", refsPerDay);

  useEffect(() => {
    const newRefsPerDay = {};
    refs.forEach((ref) => {
      const date = moment(ref?.createdAt).format("YYYY-MM-DD"); // Extract the date from the timestamp
      newRefsPerDay[date] = (newRefsPerDay[date] || 0) + 1; // Add the credits to the corresponding date
    });
    setRefsPerDay(newRefsPerDay);
  }, [refs]);

  return {
    data: refsPerDay,
    error,
    loading,
  };
};
export const useGetCommissions = () => {
  const {data, error, loading, refetch} = useQuery(GET_COMMISSIONS);
  useRefetchOnFocus(refetch);

  return {
    data: data?.getCommissions || [],
    error,
    loading,
  };
};
export const useGetRefHistory = () => {
  const {data, error, loading, refetch} = useQuery(GET_REFERRAL_HISTORY);
  useRefetchOnFocus(refetch);

  return {
    data: data?.getReferralHistory || [],
    error,
    loading,
  };
};

export const useGetReferralLink = () => {
  const [isLinkCopied, setLinkCopied] = useState(false);
  const {user} = useVarAuth();
  const refLink = `https://buythedip.com/?referrer=${user.referralCode}`;
  const handleCopyLink = () => {
    navigator.clipboard.writeText(refLink);
    setLinkCopied(true);
    setTimeout(() => setLinkCopied(false), 3000);
  };
  return {
    refLink,
    handleCopyLink,
    isLinkCopied,
  };
};
