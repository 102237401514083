import { Navigate, Outlet } from "react-router-dom";
import { useVarAuth } from "../graphql/graphql/auth/defaults";

const ProtectedRoutes = () => {
  const { user } = useVarAuth();
  //console.log("🚀 ~ file: unProtectedRoutes.js:6 ~ ProtectedRoutes ~ user", user);

  if (!user) {
    return <Outlet />;
  }
  if (user.planEnds < new Date() || user.planStatus !== "subscribed") {
    return <Outlet />;
  }
  return <Navigate to="/dashboard" />;
};
export default ProtectedRoutes;
