import {setAuthDefaults} from "./graphql/auth/defaults";
import {authMutation} from "./graphql/auth/resolvers";
import {setCommonDefaults} from "./graphql/common/defaults";

export const setDefaults = () => {
  authMutation.checkAuth({}).finally(() => {
    // console.log("🚀 ~ file: index.ts ~ line 14 ~ setDefaults ~ authMutation.checkAuth");
  });

  setCommonDefaults();
  setAuthDefaults();
};
