import {useContext} from "react";
import {Typography} from "@mui/material";
import {MenuContext} from "../../../contexts/menuContext";

export default ({text, btnText = "", onClick = undefined}) => {
  const {isSmall} = useContext(MenuContext);
  return (
    <Typography mb={3} mt={isSmall ? 2 : 3} color="rgba(255, 255, 255, 0.87)" textAlign="center">
      {text}
      {btnText && (
        <Typography
          component={"span"}
          onClick={onClick}
          sx={{
            color: "#eda044",
            cursor: "pointer",
            pl: "4px",
            display: "inline-block",
            fontWeight: 700,
          }}
        >
          {btnText}
        </Typography>
      )}
    </Typography>
  );
};
