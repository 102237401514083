import {useContext} from "react";
import styled from "styled-components";
import {Typography} from "@mui/material";
import {ArrowForward} from "@mui/icons-material";
import {MenuContext} from "../../../contexts/menuContext";

export default ({text, btnText = "", onClick = undefined}) => {
  const {isSmall} = useContext(MenuContext);
  return (
    <Title>
      {text}
      {isSmall && btnText && (
        <Typography
          component={"span"}
          onClick={onClick}
          sx={{
            color: "#eda044",
            cursor: "pointer",
            pl: "14px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: 700,
            gap: "12px",
          }}
        >
          {btnText}
          <ArrowForward />
        </Typography>
      )}
    </Title>
  );
};

const Title = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.02em;
  color: rgba(255, 255, 255, 0.87);
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  @media (max-width: 899px) {
    margin-bottom: 20px;
    font-size: 24px;
  }
`;
