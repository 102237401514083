import {Box, OutlinedInput, TextField, Typography} from "@mui/material";

export default ({label, ...props}) => {
  return (
    <Box display="flex" flexDirection="column" gap="6px">
      <Typography variant="h6" textAlign="left">
        {label}
      </Typography>
      <TextField
        {...props}
        required
        sx={{
          ".MuiOutlinedInput-root.Mui-disabled": {
            background: "linear-gradient(0deg, rgba(237, 160, 68, 0.1), rgba(237, 160, 68, 0.1)), #0F0F0F;",
          },
          ".MuiOutlinedInput-input.Mui-disabled": {
            "-webkit-text-fill-color": "#fff",
          },
        }}
      />
    </Box>
  );
};
