import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {toast} from "react-toastify";
import {
  ATTACH_STRIPE_PAYMENT_METHOD,
  CANCEL_SUBSCRIPTION,
  CREATE_STRIPE_CHECKOUT_SESSION,
  GET_PAYMENT_METHODS,
  GET_STRIPE_PRICES,
  GET_STRIPE_SUBSCRIPTION,
  GET_SUBSCRIPTION_DATA_FOR_UPGRADE_TO_YEARLY,
  REMOVE_PAYMENT_METHOD,
  SET_DEFAULT_PAYMENT_METHOD,
  UNDO_CANCEL_SUBSCRIPTION,
  UPDATE_PAYMENT_METHOD,
  UPDATE_SUBSCRIPTION_TO_YEARLY,
  UPDATE_SUBSCRIPTION_WITH_PRORATED,
} from "./gql";
import {useVarAuth} from "../auth/defaults";

export const useGetStripePrices = () => {
  const {isLogin} = useVarAuth();

  const {data, loading, error} = useQuery(GET_STRIPE_PRICES, {
    skip: !isLogin,
  });

  return {
    prices: data?.getStripePrices,
    loading,
    error,
  };
};
export const useCreateStripeCheckoutSession = () => {
  const [createCheckoutSession, {loading, error}] = useMutation(CREATE_STRIPE_CHECKOUT_SESSION, {
    onCompleted: (data) => {
      window.location.href = data?.stripeCheckout;
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  return {
    createCheckoutSession,
    loading,
    error,
  };
};

export const useGetPaymentMethods = () => {
  const {data, loading, error} = useQuery(GET_PAYMENT_METHODS);

  return {
    paymentMethods: data?.stripePaymentMethods,
    loading,
    error,
  };
};

export const useGetStripeSubscription = () => {
  const {data, loading, error} = useQuery(GET_STRIPE_SUBSCRIPTION);

  return {
    subscription: data?.stripeSubscription,
    loading,
    error,
  };
};

export const useGetSubscriptionDataForUpgradeToYearly = () => {
  const [getUpgradeData, {loading, error}] = useLazyQuery(GET_SUBSCRIPTION_DATA_FOR_UPGRADE_TO_YEARLY);

  return {
    getUpgradeData,
    loading,
    error,
  };
};

export const useStripePaymentMethod = () => {
  const [updatePaymentMethod, updateProps] = useMutation(UPDATE_PAYMENT_METHOD, {
    refetchQueries: [{query: GET_PAYMENT_METHODS}],
    onCompleted: () => {
      toast.success("Payment method updated");
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const [addPaymentMethod, addProps] = useMutation(ATTACH_STRIPE_PAYMENT_METHOD, {
    refetchQueries: [{query: GET_PAYMENT_METHODS}],
    onCompleted: () => {
      toast.success("Payment method added");
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const [updateDefaultPaymentMethod, updateDefaultProps] = useMutation(SET_DEFAULT_PAYMENT_METHOD, {
    refetchQueries: [{query: GET_PAYMENT_METHODS}],
    onCompleted: () => {
      toast.success("Default payment method updated");
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const [removePaymentMethod, removeProps] = useMutation(REMOVE_PAYMENT_METHOD, {
    refetchQueries: [{query: GET_PAYMENT_METHODS}],
    onCompleted: () => {
      toast.success("Payment method removed");
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  return {
    updatePaymentMethod,
    addPaymentMethod,
    updateDefaultPaymentMethod,
    removePaymentMethod,
    loading: updateProps.loading || addProps.loading || updateDefaultProps.loading || removeProps.loading,
    error: updateProps.error || addProps.error || updateDefaultProps.error || removeProps.error,
  };
};

export const useHandleStripeSubscription = () => {
  const [updateSubscriptionWithProrated, updateProps] = useMutation(UPDATE_SUBSCRIPTION_WITH_PRORATED, {
    refetchQueries: [{query: GET_STRIPE_SUBSCRIPTION}],
    onCompleted: () => {
      toast.success("Subscription updated");
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const [cancelSubscription, cancelProps] = useMutation(CANCEL_SUBSCRIPTION, {
    refetchQueries: [{query: GET_STRIPE_SUBSCRIPTION}],
    onCompleted: () => {
      toast.success("Subscription cancelled");
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const [undoCancelSubscription, undoCancelProps] = useMutation(UNDO_CANCEL_SUBSCRIPTION, {
    refetchQueries: [{query: GET_STRIPE_SUBSCRIPTION}],
    onCompleted: () => {
      toast.success("Subscription active again");
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const [updateSubscriptionToYearly, updateYearlyProps] = useMutation(UPDATE_SUBSCRIPTION_TO_YEARLY, {
    refetchQueries: [{query: GET_STRIPE_SUBSCRIPTION}],
    onCompleted: () => {
      toast.success("Subscription updated");
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  return {
    updateSubscriptionWithProrated,
    updateSubscriptionToYearly,
    cancelSubscription,
    undoCancelSubscription,
    loading: updateProps.loading || cancelProps.loading || undoCancelProps.loading || updateYearlyProps.loading,
    error: updateProps.error || cancelProps.error || undoCancelProps.error || updateYearlyProps.error,
  };
};
