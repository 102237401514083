// const endpointDev = "http://localhost:3001/graphql";
// export const endpointDev = process.env.REACT_APP_BACKEND_BASE_URL + "/graphql";
export const endpointDev = "https://apidev.buythedip.com/btdbe2/graphql";

// const endpointProd = "https://instant-app-builder.herokuapp.com/graphql";
export const endpointProd = process.env.REACT_APP_BACKEND_BASE_URL + "/graphql";

export const endpoint = process.env.NODE_ENV === "development" ? endpointDev : endpointProd;

export const wsEndPoint = endpoint.replace("http", "ws");
