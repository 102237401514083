import {gql} from "@apollo/client";

export const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    id
    name
    email
    planEnds
    planStatus
    referralCode
    createdAt
    avatar
    userName
  }
`;

export const CURRENT_USER = gql`
  query CURRENT_USER {
    me {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const GET_TOKEN = gql`
  query GET_TOKEN {
    getToken {
      token
      user {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;
export const SEND_OTP = gql`
  mutation SEND_OTP($input: MutationSendOtpInput!) {
    sendOtp(input: $input)
  }
`;
export const VERIFY_OTP = gql`
  mutation VERIFY_OTP($input: MutationVerifyOtpInput!) {
    verifyOtp(input: $input) {
      token
      user {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const LOG_OUT_SERVER = gql`
  mutation {
    signOut
  }
`;
