import {gql} from "@apollo/client";
export const GET_STRIPE_PRICES = gql`
  query GET_STRIPE_PRICES {
    getStripePrices
  }
`;

export const CREATE_STRIPE_CHECKOUT_SESSION = gql`
  mutation CREATE_STRIPE_CHECKOUT_SESSION($input: MutationStripeCheckoutInput!) {
    stripeCheckout(input: $input)
  }
`;

export const GET_STRIPE_SUBSCRIPTION = gql`
  query GET_STRIPE_SUBSCRIPTION {
    stripeSubscription {
      plan
      cancel_at_period_end
      end_date
      price
    }
  }
`;
export const UPDATE_PAYMENT_METHOD = gql`
  mutation UPDATE_PAYMENT_METHOD($input: MutationUpdateStripePaymentMethodInput!) {
    updateStripePaymentMethod(input: $input)
  }
`;

export const ATTACH_STRIPE_PAYMENT_METHOD = gql`
  mutation ATTACH_STRIPE_PAYMENT_METHOD($input: MutationAttechStripePaymentMethodsInput!) {
    attechStripePaymentMethods(input: $input)
  }
`;

export const GET_PAYMENT_METHODS = gql`
  query GET_PAYMENT_METHODS {
    stripePaymentMethods
  }
`;

export const SET_DEFAULT_PAYMENT_METHOD = gql`
  mutation SET_DEFAULT_PAYMENT_METHOD($input: MutationSetDefaultPaymentMethodInput!) {
    setDefaultPaymentMethod(input: $input)
  }
`;

export const REMOVE_PAYMENT_METHOD = gql`
  mutation REMOVE_PAYMENT_METHOD($input: MutationRemovePaymentMethodInput!) {
    removePaymentMethod(input: $input)
  }
`;

export const UPDATE_SUBSCRIPTION_WITH_PRORATED = gql`
  mutation UPDATE_SUBSCRIPTION_WITH_PRORATED {
    updateSubscriptionWithProrated
  }
`;

export const CANCEL_SUBSCRIPTION = gql`
  mutation CANCEL_SUBSCRIPTION {
    cancelSubscription
  }
`;

export const UNDO_CANCEL_SUBSCRIPTION = gql`
  mutation UNDO_CANCEL_SUBSCRIPTION {
    undoCancelSubscription
  }
`;

export const UPDATE_SUBSCRIPTION_TO_YEARLY = gql`
  mutation UPDATE_SUBSCRIPTION_TO_YEARLY {
    updateSubscriptionToYearly
  }
`;

export const GET_SUBSCRIPTION_DATA_FOR_UPGRADE_TO_YEARLY = gql`
  query GET_SUBSCRIPTION_DATA_FOR_UPGRADE_TO_YEARLY {
    subscriptionDataForUpgradeToYearly {
      defaultPaymentMethod
      amount

      nextBillingDate
    }
  }
`;
