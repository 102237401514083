import { Navigate, Outlet } from "react-router-dom";
import { useVarAuth } from "../graphql/graphql/auth/defaults";

const ProtectedRoutes = (props) => {
  const { user } = useVarAuth();
  //console.log("🚀 ~ file: protectedRoutes.js:6 ~ ProtectedRoutes ~ user", user);
  if (!user) {
    return <Navigate to="/" />;
  }
  if (user.planEnds < new Date() || user.planStatus !== "subscribed") {
    return <Navigate to="/mustSubscribe" />;
  }
  return <Outlet />;
};
export default ProtectedRoutes;
