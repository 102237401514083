import {useContext} from "react";
import {useNavigate} from "react-router-dom";
import {Box, FormControl, Grid, OutlinedInput, Typography} from "@mui/material";
import parse from "html-react-parser";
import styled from "styled-components";
import Premium from "../../assets/images/premium.svg";
import LayerLogo from "../../assets/images/LayerLogo.svg";
import {MenuContext} from "../../contexts/menuContext";
import "../../pages/auth/styles.css";

export default ({showInfo = false, maxWidth = 421, children}) => {
  const {isSmall} = useContext(MenuContext);
  const navigate = useNavigate();
  return (
    <Grid item xs={12} md={7}>
      <Container className="sigupRightPanel">
        <Box width={isSmall ? "100%" : `${maxWidth}px`}>
          {showInfo && (
            <Box position="absolute" display="flex" alignItems="center" gap="12px" top="30px" right="30px">
              <img src={Premium} alt="logo" />
              <Typography fontSize="14px" color="#FFFFFFDE">
                Already have an account?
                <Typography
                  component={"span"}
                  onClick={() => navigate("/")}
                  sx={{
                    color: "#eda044",
                    cursor: "pointer",
                    pl: "4px",
                    fontWeight: 700,
                  }}
                >
                  Log in instead
                </Typography>
              </Typography>
            </Box>
          )}
          {children}
        </Box>
      </Container>
    </Grid>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin-top: -10px;

  @media (max-width: 900px) {
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }
`;
